<template>
    <div class="dropdown" @click="toggle">
      <span class="text">{{ name }}</span>
      <span class="arrow" :class="{ 'up': isExpanded }">&#9660;</span>
    </div>
    <transition name="content">
        <div v-if="isExpanded" class="content">
            {{ text }}
        </div>
    </transition>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isExpanded: false
      };
    },
    props: {
        name: String,
        text: String,
    },
    methods: {
      toggle() {
        this.isExpanded = !this.isExpanded;
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  .dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background: white;
    padding: 1rem 2rem;
    transition: all .5s ease;
    font-family: "HelveticaMedium";
    font-size: 1.1rem;

    &:hover {
        background-color: rgba(0, 0, 0, .3);
    }
  }
  
  .arrow {
    transition: transform 0.3s ease;
  }
  
  .arrow.up {
    transform: rotate(180deg);
  }
  
  .content {
    overflow: hidden;
    font-family: "HelveticaMedium";
    font-size: 1.1rem;
    padding: 0 2rem;
  }
  
  .content-enter-active, .content-leave-active {
    transition: opacity 0.7s ease;
  }
  
  .content-enter-from, .content-leave-to {
    opacity: 0;
  }
  
</style>