<template>
  <div>
    <BusinessPageFirst/>
    <BusinessPageSecond/>
  </div>
</template>

<script>
// @ is an alias to /src
import BusinessPageFirst from '@/components/BusinessPage/BusinessPageFirst';
import BusinessPageSecond from '@/components/BusinessPage/BusinessPageSecond';

export default {
  name: 'MalaysiaView',
  components: {
    BusinessPageFirst,
    BusinessPageSecond,
  }
}
</script>

<style scoped>

</style>