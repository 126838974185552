<template>
  <div class="info-block">
    <div class="text-block content-block" v-html="text" ref="textBlock">
    </div>
    <div class="program-block content-block">
      <h2 class="red">
        {{ this.$langStore.lang === "ru" ? "Программы" : "Programs:" }}
      </h2>
      <SlideTextComponent :tabs="slider" />
    </div>
    <div class="content-block">
        <CarouselComponent :items="feedbackArray"/>

        <FeedbackForm page="business" />
      </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import SlideTextComponent from '../SlideTextComponent.vue';
import CarouselComponent from '../CarouselComponent.vue';
import FeedbackForm from '../Forms/FeedbackForm.vue';

export default {
  name: 'BusinessPageSecond',
  components: {
    SlideTextComponent,
    CarouselComponent,
    FeedbackForm,
  },
  data() {
    return {
      text: "",
      slider: [
        {
          name: "УПРАВЛЕНИЕ БИЗНЕСОМ",
          component: "ManageTab",
        },
        {
          name: "УПРАВЛЕНИЕ ГОСТИНИЧНЫМ БИЗНЕСОМ",
          component: "HotelTab",
        },
        {
          name: "ИНФОРМАЦИОННЫЕ ТЕХНОЛОГИИ",
          component: "ITTab",
        },
        {
          name: "ТРЕНИНГИ ПОВЫШЕНИЯ ПРОФЕССИОНАЛЬНОЙ КВАЛИФИКАЦИИ",
          component: "TrainingTab",
        },
      ],
    }
  },
  setup() {
    const feedbackArray = ref([]);

    
    const loadModeratedFeedback = () => {
      fetch(process.env.VUE_APP_API_URL + `/api/feedback/moderated/business`)
          .then(response => response.json())
          .then(data => {
            if (data.detail) return;
            feedbackArray.value = data
            feedbackArray.value.forEach(element => {
                element.photo = process.env.VUE_APP_API_URL + "/api/files/feedback/" + element.photo
              });
          })
          .catch(error => console.error('Ошибка загрузки контента:', error));
    };

    loadModeratedFeedback();

    return {
      feedbackArray,
    }
  },
  mounted() {
    this.fetchContent();

    const fromLeftObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('who-visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const textBlock = this.$refs.textBlock;

    fromLeftObserver.observe(textBlock);
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/get/content/${this.$langStore.lang}/business`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
        this.text = data.text;
        this.slider = [
          {
            name: this.$langStore.lang === "ru" ? "Управление бизнесом" : "Business Management",
            component: "ManageTab",
            text: data.manage,
          },
          {
            name: this.$langStore.lang === "ru" ? "Управление гостиничным бизнесом" : "Hospitality Management",
            component: "HotelTab",
            text: data.hotel,
          },
          {
            name: this.$langStore.lang === "ru" ? "Информационные технологии" : "Information Technology",
            component: "ITTab",
            text: data.it,
          },
          {
            name: this.$langStore.lang === "ru" ? "Тренинги повышения профессиональной квалификации" : "Professional Development Trainings",
            component: "TrainingTab",
            text: data.training,
          },
        ];

      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @keyframes slideInFromLeft {
      0% {
          transform: translateX(-100%);
          opacity: 0;
      }
      100% {
          transform: translateX(0);
          opacity: 1;
      }
  }

  @keyframes slideInFromRight {
      0% {
          transform: translateX(100%);
          opacity: 0;
      }
      100% {
          transform: translateX(0);
          opacity: 1;
      }
  }

.info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('~@/img/sky-with-clouds.jpg') no-repeat;
  background-size: cover;
  padding-bottom: 4rem;
  min-height: 100dvh;
}

.info-block::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  z-index: 0;
}

.school-education {
  h2 {
    text-transform: uppercase;
    font-family: "Ag Fatum";
  }

  p {
    color: black;
    font-size: 1.2rem;
    margin-top: 2rem;
  }
}

:deep(.text-block) {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  color: black;
  visibility: hidden;

  h2 {
    margin-bottom: 2.5rem;
  }
}

.program-block {
  color: black;

  h2 {
    margin-bottom: 2.5rem;
  }
}

.who-visible { 
    animation-name: slideInFromLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    visibility: visible;
}
</style>