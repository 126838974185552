<template>
  <div class="info-block">
    <div class="malaysia content-block">
      <h2 class="red">
        {{ header }}
      </h2>
      <div v-html="text">
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MalaysiaPageSecond',
  data() {
    return {
      text: "",
      header: this.$langStore.lang === "ru" ? "ОБ ОБРАЗОВАНИИ В МАЛАЙЗИИ" : "ABOUT EDUCATION IN MALAYSIA"
    }
  },
  mounted() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/get/content/${this.$langStore.lang}/why_malaysia`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
        this.text = data.text;

      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @keyframes slideInFromLeft {
      0% {
          transform: translateX(-100%);
          opacity: 0;
      }
      100% {
          transform: translateX(0);
          opacity: 1;
      }
  }

  @keyframes slideInFromRight {
      0% {
          transform: translateX(100%);
          opacity: 0;
      }
      100% {
          transform: translateX(0);
          opacity: 1;
      }
  }

.info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background: url('~@/img/sky-with-clouds.jpg') no-repeat;
  background-size: cover;
  padding-bottom: 4rem;
}

.info-block::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  z-index: 0;
}

:deep(.malaysia) {
  p {
    font-size: 1.1rem;
    color: black;
    text-align: left;
  }

  h2 {
    margin-bottom: 2rem;
  }

  div {
    font-size: 1.1rem;
    color: black;
    text-align: left;
    white-space: wrap;
    margin-top: 1rem;

    p {
      margin-bottom: 1rem;
    }

  }
}


</style>