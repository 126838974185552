<template>
    <div class="carousel-container">
      <div class="carousel" v-if="items">
        <transition-group name="slide" tag="div" mode="out-in" class="carousel-content">
          <div
            class="carousel-item"
            v-for="(item, index) in items"
            :key="index"
            v-show="index === activeIndex"
          >
            <p class="text">{{ item.content }}</p>
            <div class="author">
                <img :src="item.photo" :alt="item.name" class="photo">
                <h3 class="name">{{ item.name }}</h3>
            </div>
          </div>
        </transition-group>
      </div>
      <button class="arrow left-arrow" @click="prevItem">&lt;</button>
      <button class="arrow right-arrow" @click="nextItem">&gt;</button>
      <div class="dots">
        <span
          class="dot"
          v-for="(item, index) in items"
          :key="index"
          :class="{ active: index === activeIndex }"
          @click="setActive(index)"
        ></span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CarouselComponent",
    props: {
      items: Array
    },
    data() {
      return {
        activeIndex: 0,
        timer: null
      };
    },
    methods: {
      nextItem() {
        this.activeIndex = (this.activeIndex + 1) % this.items.length;
        this.resetTimer();
      },
      prevItem() {
        this.activeIndex =
          (this.activeIndex + this.items.length - 1) % this.items.length;
        this.resetTimer();
      },
      setActive(index) {
        this.activeIndex = index;
        this.resetTimer();
      },
      resetTimer() {
        clearInterval(this.timer);
        this.timer = setInterval(this.nextItem, 5000);
      }
    },
    mounted() {
      this.resetTimer();
    },
    beforeUnmount() {
      clearInterval(this.timer);
    }
  };
  </script>
  
  <style scoped lang="scss">
  .carousel-container {
    position: relative;
    width: 80%;
    margin: 0 auto;
  }
  .carousel {
    overflow: hidden;
  }
  .carousel-content {
    display: flex;
    transition: all 0.5s ease;
  }
  .carousel-item {
    min-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: black;
  }

  .author {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .text {
    min-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
  }
  .photo {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .name {
    font-size: 1rem;
  }
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    background: none;
    cursor: pointer;
    font-size: 3rem;
    z-index: 2;
  }
  .left-arrow {
    left: 10px;
  }
  .right-arrow {
    right: 10px;
  }
  .dots {
    text-align: center;
    position: absolute;
    bottom: -4vh;
    width: 100%;
    }

    .dot {
        display: inline-block;
        margin: 0 5px;
        width: 10px;
        height: 10px;
        background-color: black;
        border-radius: 50%;
        transition: background-color 0.3s;
        cursor: pointer;
    }

    .dot.active {
        background-color: #d91d1c;
    }

.slide-enter-active, .slide-leave-active {
    transition: all 0.5s;
}
.slide-enter-from, .slide-leave-to /* .slide-leave-active до версии 2.1.8 */ {
    opacity: 0;
}
  </style>