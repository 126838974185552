import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import {useLangStore} from '@/stores/lang';

import TwoWeeksTab from '@/components/CampPage/Tabs/TwoWeeksTab'
import ThreeWeeksTab from '@/components/CampPage/Tabs/ThreeWeeksTab'

import PreSchoolTab from './components/SchoolPage/Tabs/PreSchoolTab.vue'
import SchoolTab from './components/SchoolPage/Tabs/SchoolTab.vue'
import MidSchoolTab from './components/SchoolPage/Tabs/MidSchoolTab.vue'

import BachelorTab from './components/UniversityPage/Tabs/BachelorTab.vue'
import MagistracyTab from './components/UniversityPage/Tabs/MagistracyTab.vue'
import DoctoralTab from './components/UniversityPage/Tabs/DoctoralTab.vue'

import ManageTab from './components/BusinessPage/Tabs/ManageTab.vue'
import HotelTab from './components/BusinessPage/Tabs/HotelTab.vue'
import ITTab from './components/BusinessPage/Tabs/ITTab.vue'
import TrainingTab from './components/BusinessPage/Tabs/TrainingTab.vue'

const app = createApp(App)
const pinia = createPinia()

app.component("TwoWeeksTab", TwoWeeksTab)
app.component("ThreeWeeksTab", ThreeWeeksTab)

app.component("PreSchoolTab", PreSchoolTab)
app.component("SchoolTab", SchoolTab)
app.component("MidSchoolTab", MidSchoolTab)

app.component("BachelorTab", BachelorTab)
app.component("MagistracyTab", MagistracyTab)
app.component("DoctoralTab", DoctoralTab)

app.component("ManageTab", ManageTab)
app.component("HotelTab", HotelTab)
app.component("ITTab", ITTab)
app.component("TrainingTab", TrainingTab)

app.use(pinia)

app.config.globalProperties.$langStore = useLangStore();

app.use(router).mount('#app')