<template>
  <div>
    <MainPageFirst/>
    <MainPageSecond/>
  </div>
</template>

<script>
// @ is an alias to /src
import MainPageFirst from '@/components/MainPage/MainPageFirst.vue';
import MainPageSecond from '@/components/MainPage/MainPageSecond.vue';

export default {
  name: 'HomeView',
  components: {
    MainPageFirst,
    MainPageSecond,
  }
}
</script>

<style scoped>

</style>