<template>
    <a class="button" href="https://www.google.ru/" target="_blank">
        {{ this.$langStore.lang === "ru" ? "Пройти онлайн тестирование" : "TAKE AN ONLINE TEST" }}
    </a>
</template>

<script>
export default {
    name: "OnlineTestButton",
}
</script>

<style lang="scss" scoped>
    .button {
        width: 480px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 1rem 1rem;
        border-radius: 1.5rem;
        background: white;
        color: #d91d1c;
        font-weight: bold;
        font-family: "Ag Fatum";
        text-transform: uppercase;
        font-size: 1.5rem;
        height: 2rem;
        grid-column: 2;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6), 6px 6px 6px rgba(0, 0, 0, 0.6);
    }
</style>