<template>
    <div class="lang-toggler" @click="toggleLang">
        <transition name="langFade">
            <img :src="imagePath" :class="imageClass" :alt="lang">
        </transition>
    </div>
</template>

<script>
import {ref} from 'vue';
import {useLangStore} from '@/stores/lang';

export default {
    name: "LanguageComponent",
    data() {
        return {
            imgPath: "",
        }
    },
    setup() {
        const langStore = useLangStore();

        const imagePath = ref('');
        const imageClass = ref('fade-in');

        const loadImage = (lang) => {
            imageClass.value = 'fade-out';
            setTimeout(() => {
                import(`@/img/lang/${lang}.svg`)
                .then((image) => {
                    imagePath.value = image.default;
                    imageClass.value = 'fade-in';
                })
                .catch(error => {
                    console.error('Ошибка при загрузке изображения:', error);
                });
            }, 500)

        };

        return {
            loadImage,
            imagePath,
            langStore,
            imageClass
        }
    },

    mounted() {
        this.image = this.langStore.image;
        this.loadImage(this.image);
    },

    methods: {
        toggleLang() {
            this.langStore.change();
            this.image = this.langStore.image;
            this.loadImage(this.image);
            this.$router.go()
        }
    }
}
</script>

<style lang="scss" scoped>
    .lang-toggler {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 60px;
        height: 60px;
        z-index: 200;
        cursor: pointer;
        transition: all .5s ease;

        &:hover {
            width: 80px;
            height: 80px;
        }

        img {
            transition: opacity 0.5s ease-in-out;
        }
    }

    .fade-out {
        opacity: 0;
    }

    .fade-in {
        opacity: 1;
    }
</style>