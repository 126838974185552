<template>
    <div class="weeks-tab" v-html="text">
    </div>
</template>

<script>
export default {
    name: "TwoWeeksTab",
    props: {
        text: String
    }
}
</script>

<style lang="scss">

    .weeks-tab {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-family: "HelveticaMedium";
        font-size: 1.1rem;

        .weeks-tab__dates {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            color: black;
        }

        .weeks-tab__program {
            h3 {
                color: #d91d1c;
                margin-bottom: 1rem;
                font-family: "Ag Fatum";
            }

            ul {
                list-style-position: inside;
            }

            li {
                color: black;
            }
        }
    }

</style>