<template>
    <div class="business-tab" v-html="text">
    </div>
</template>

<script>
export default {
    name: "ITTab",
    props: {
        text: String,
    }
}
</script>

<style lang="scss" scoped>

    .business-tab {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-family: "HelveticaMedium";
        font-size: 1.1rem;
        text-align: start;

        :deep(.weeks-tab__program) {
            h3 {
                color: #d91d1c;
                margin-bottom: 1rem;
                font-family: "Ag Fatum";
            }

            ul {
                list-style-position: inside;
            }

            li {
                color: black;
            }
        }
    }

</style>