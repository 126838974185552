<template>
  <div>
    <CampPageFirst/>
    <CampPageSecond/>
  </div>
</template>

<script>
// @ is an alias to /src
import CampPageFirst from '@/components/CampPage/CampPageFirst';
import CampPageSecond from '@/components/CampPage/CampPageSecond';

export default {
  name: 'CampView',
  components: {
    CampPageFirst,
    CampPageSecond,
  }
}
</script>

<style scoped>

</style>