<template>
    <div class="footer-container">
      <!-- Кнопка со стрелкой вверх -->
      <transition name="fade">
        <button v-if="!isBlockVisible" @click.stop="toggleBlock" class="up-button">
            ‹
        </button>
      </transition>
  
      <!-- Анимированный блок, который появляется после нажатия на кнопку -->
      <transition name="slide" mode="out-in">
        <div ref="footerContent" class="content-block" :class="{'start-animation': isBlockVisible}">
          <div class="arrow" @click.stop="toggleBlock">
            ›
          </div>
          <div class="menuElems">
            <div class="menuElem"><router-link to="/school">
              {{ this.$langStore.lang === "ru" ? "Школьное образование" : "School Education" }}
            </router-link></div>
            <div class="menuElem"><router-link to="/camp">
              {{ this.$langStore.lang === "ru" ? "Языковые курсы" : "Language Courses" }}</router-link></div>
            <div class="menuElem"><router-link to="/university">
              {{ this.$langStore.lang === "ru" ? "Высшее образование" : "Higher Education" }}
            </router-link></div>
            <div class="menuElem"><router-link to="/additional">
              {{ this.$langStore.lang === "ru" ? "Дополнительное профильное образование" : "Additional Specialized Education" }}
            </router-link></div>
            <div class="menuElem"><router-link to="/business">
              {{ this.$langStore.lang === "ru" ? "Бизнес-тренинги" : "Business-training" }}
            </router-link></div>
          </div>

          <div class="contacts">
            <span style="cursor: pointer;" @click="toggleModal">
              {{ this.$langStore.lang === "ru" ? "Обратная связь" : "Feedback" }}
            </span>
            <span>
              {{ this.$langStore.lang === "ru" ? "Контакты:" : "Contacts:" }}
            </span>
            <a href="tel:+60132228543">+60132228543</a>
            <a href="tel:+79025550208">+79025550208</a>
            <a href="mailto:manager@education-malaysia.ru">manager@education-malaysia.ru</a>
          </div>

          <div class="rights">
            <span>
              {{ this.$langStore.lang === "ru" ? "Все права защищены" : "All rights reserved" }}
            </span>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import {EventBus} from '@/eventBus/EventBus.js';

  export default {
    data() {
      return {
        isBlockVisible: false,
      };
    },
    methods: {
      toggleBlock() {
        this.isBlockVisible = !this.isBlockVisible;
      },
      closeBlock(event) {
            // Проверяем, что клик был не по блоку футера и не по его дочерним элементам
            if (this.isBlockVisible && !this.$refs.footerContent.contains(event.target)) {
                this.isBlockVisible = false;
            }
        },
      toggleModal() {
        EventBus.$emit('open-feedback-modal')
      }
    },
    mounted() {
        // Добавляем обработчик события клика для всего документа
        document.addEventListener('click', this.closeBlock);
    },
    beforeUnmount() {
    // Удаляем обработчик события клика при уничтожении компонента
    document.removeEventListener('click', this.closeBlock);
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .footer-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
  
  .up-button {
    width: 100%;
    height: 1rem;
    color: white;
    background-color: #d91d1c;
    position: fixed;
    bottom: 0;
    right: 0;
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem 3rem;
    cursor: pointer;
    user-select: none;
    writing-mode:vertical-rl;
    border: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .content-block {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #d91d1c;
    height: 250px; /* Высота блока */
    transform: translateY(100%);
    transition: all 1s ease;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .arrow {
      grid-column: 1 / span 4;
      width: 100%;
      font-weight: bold;
      font-size: 2rem;
      height: 1rem;
      user-select: none;
      cursor: pointer;
      writing-mode:vertical-lr;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2rem;
      
      padding: 1rem 0;
    }

    .menuElems, .contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        
        padding: 1rem 1rem;
    }

    .menuElems {
        .menuElem {
            text-align: start;
            a {
                text-decoration: none;
                color: white;
            }
        }
    }

    .contacts {
        span {
            font-weight: bold;
        }
        a {
                text-decoration: none;
                color: white;
            }
    }

    .feedback {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #d91d1c;
        padding: 1rem 1rem;

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 1rem 3rem;
            border-radius: 3rem;
            background: #ffffff;
            height: 3rem;
        }
    }

    .rights {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        grid-column: 1 / span 4;
        padding: 1rem 1rem;

        span {
            font-weight: bold;
        }
    }
  }

  .start-animation {
    transform: translateY(0);
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  
  .slide-enter-active, .slide-leave-active {
    transition: all 1s ease;
  }
  
  .slide-enter, .slide-leave-to {
    transform: translateY(0);
  }
  </style>