<template>
    <LanguageComponent />

    <div class="bars" id="nav-action" ref="bars" v-el:bars>
    <span class="bar"> </span>
    </div>

    <nav id="nav" class="desktop nav" ref="nav" @click="this.$refs.nav.classList.toggle('visible'); this.$refs.bars.classList.toggle('active')">
    <ul>
        <li class="shape-circle" :class="circleOne">
            <router-link to="/business">
              <div id="circle1">
              </div>
            </router-link>
        </li>
        <li class="shape-circle" :class="circleTwo">
            <router-link to="/additional">
              <div id="circle2">
              </div>
            </router-link>
        </li>
        <li class="shape-circle" :class="circleThree">
            <router-link to="/university">
              <div id="circle3">
                </div>
            </router-link>
        </li>
        <li class="shape-circle" :class="circleFour">
            <router-link to="/camp">
                <div id="circle4">
                </div>
            </router-link>
        </li>
        <li class="shape-circle" :class="circleFive">
            <router-link to="/school">
                <div id="circle5">
                </div>
            </router-link>
        </li>
        <li class="shape-circle" :class="circleSix">
            <router-link to="/">
                <div id="circle6">
                </div>
            </router-link>
        </li>
    </ul>
    </nav>

    <nav class="mobile" id="navMobile" ref="navMobile">
      <ul>
        <li>
          <router-link to="/" @click="this.$refs.navMobile.classList.toggle('visible'); this.$refs.bars.classList.toggle('active')">
            <div class="nav-mobile">{{ this.$langStore.lang === "ru" ? "Главная" : "Home" }}</div>
          </router-link>
        </li>
        <li>
          <router-link to="/school" @click="this.$refs.navMobile.classList.toggle('visible'); this.$refs.bars.classList.toggle('active')">
            <div class="nav-mobile">{{ this.$langStore.lang === "ru" ? "Школьное образование" : "School Education" }}</div>
          </router-link>
        </li>
        <li>
          <router-link to="/camp" @click="this.$refs.navMobile.classList.toggle('visible'); this.$refs.bars.classList.toggle('active')">
            <div class="nav-mobile">{{ this.$langStore.lang === "ru" ? "Языковые курсы" : "Language Courses" }}</div>
          </router-link>
        </li>
        <li>
          <router-link to="/university" @click="this.$refs.navMobile.classList.toggle('visible'); this.$refs.bars.classList.toggle('active')">
            <div class="nav-mobile">{{ this.$langStore.lang === "ru" ? "Высшее образование" : "Higher Education" }}</div>
          </router-link>
        </li>
        <li>
          <router-link to="/additional" @click="this.$refs.navMobile.classList.toggle('visible'); this.$refs.bars.classList.toggle('active')">
            <div class="nav-mobile">{{ this.$langStore.lang === "ru" ? "Дополнительное профильное образование" : "Additional Specialized Education" }}</div>
          </router-link>
        </li>
        <li>
          <router-link to="/business" @click="this.$refs.navMobile.classList.toggle('visible'); this.$refs.bars.classList.toggle('active')">
            <div class="nav-mobile">{{ this.$langStore.lang === "ru" ? "Бизнес-тренинги" : "Business-training" }}</div>
          </router-link>
        </li>
      </ul>
    </nav>

</template>

<script>
import LanguageComponent from '@/components/Layout/LanguageComponent'

export default {
    name: 'MenuComponent',
    components: {
      LanguageComponent
    },
    data() {
      return {
        circleOne: this.$langStore.lang === "ru" ? "circle-one" : "circle-one-en",
        circleTwo: this.$langStore.lang === "ru" ? "circle-two" : "circle-two-en",
        circleThree: this.$langStore.lang === "ru" ? "circle-three" : "circle-three-en",
        circleFour: this.$langStore.lang === "ru" ? "circle-four" : "circle-four-en",
        circleFive: this.$langStore.lang === "ru" ? "circle-five" : "circle-five-en",
        circleSix: this.$langStore.lang === "ru" ? "circle-six" : "circle-six-en",
      }
    },
    mounted: function () {

        // Setting up the Variables
        var bars = document.getElementById("nav-action");
        var nav = document.getElementById("nav");
        var navMobile = document.getElementById("navMobile");


        //setting up the listener
        bars.addEventListener("click", barClicked, false);


        //setting up the clicked Effect
        function barClicked() {
            bars.classList.toggle('active');
            nav.classList.toggle('visible');
            navMobile.classList.toggle('visible');
        }

        document.addEventListener("scroll", function () {
          bars.classList.remove("active");
          nav.classList.remove('visible');
          navMobile.classList.remove('visible');
        })

        setTimeout(() => {   
          if (window.screen.width > 768) {
            bars.classList.toggle('active');
          }
          nav.classList.toggle('visible');
        }, 100)
    },
    methods: {
        vw: function(percent) {
            var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            return (percent * w) / 100;
        }
    }
}
</script>

<style lang="scss" scoped>
$items: 6;

    .bars {
  /*   background-color:pink; */
  position: fixed;
  width: 27px;
  height: 27px;
  top: 30px;
  left: 30px;
  cursor: pointer;
  z-index: 101;
  padding-top:9px;
}

.bar {
  width: 100%;
  height: 4px;
  background-color: #fff;
  position: absolute;
}

span::before,
span::after {
  content: "";
  display: block;
  background-color: #fff;
  width: 100%;
  height: 4px;
  position: absolute;
}

.bar::before {
  transform: translateY(-9px);
}

.bar::after {
  transform: translateY(9px);
}

.bars.active .bar {
  background-color: transparent;
}

.bars.active span::before {
  animation: top-bar 1s;
  animation-fill-mode: forwards;
}

.bars.active span::after {
  animation: bottom-bar 1s;
  animation-fill-mode: forwards;
}


/* Navbar Links CSS */

#nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 1s;
  z-index: -1;
  overflow: hidden;
  opacity: 0;
  max-height: 100dvh;
}

#navMobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 1s;
  z-index: -1;
  opacity: 0;
  max-height: 100dvh;

  ul {
    a {
      text-decoration: none;

      .nav-mobile {
        color: white;
        background: #d91d1c;
        padding: 1.5rem;
        text-align: center;
        box-shadow: 0 0px 80px rgba(4, 26, 62, 0.5);
        font-size: 2rem;
        text-transform: uppercase;
      }
    }
  }
}

#nav a {
  color: #fff;
  text-decoration: none;
  border-radius: 50%;
  transition: all .5s;
}

ul {
  list-style: none;
}

.visible {
  z-index: 100 !important;
  opacity: 1 !important;
}

.shape-circle {
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  top: -10vw;
  left: -10vw;
  position: absolute;
  transition: all 1s ease-in-out;
  background: #d91d1c;
  box-shadow: 0 0px 0px rgba(122, 5, 5, 0.5);
}

@for $i from 1 through $items {
    $rad : 100vw - ($i * 10);
    $delta : calc($rad / 2);
    $transition: 0.4 + calc($i / 10) * 1s;

    #nav.visible li:nth-child(#{$i}) {
        width: $rad;
        height: $rad;
        top: -$delta;
        left: -$delta;
        z-index: 4 + $i;
        transition: all $transition ease-in-out;
        box-shadow: 0 0px 80px rgba(4, 26, 62, 0.5);
        display: flex;
        justify-content: center;
        align-items: flex-end;

        a{
            width: $rad;
            height: 100%;
            font-family: "Ag Fatum";
            font-size: 20px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
}

.circle-six {
  background-image: url("~@/img/main.svg");
  background-repeat: no-repeat;
  background-size: 8vw;
  background-position: bottom 1vw right 6vw;
}

.circle-five {
  background-image: url("~@/img/school.svg");
  background-repeat: no-repeat;
  background-size: 16vw;
  background-position: bottom 1.8vw right 0.2vw;
}

.circle-four {
  background-image: url("~@/img/courses.svg");
  background-repeat: no-repeat;
  background-size: 16vw;
  background-position: bottom .8vw right 7.3vw;
}

.circle-three {
  background-image: url("~@/img/university.svg");
  background-repeat: no-repeat;
  background-size: 15.5vw;
  background-position: bottom 4.4vw right 3.2vw;
}

.circle-two {
  background-image: url("~@/img/dop.svg");
  background-repeat: no-repeat;
  background-size: 31vw;
  background-position: bottom .8vw right 1.4vw;
}

.circle-one {
  background-image: url("~@/img/business.svg");
  background-repeat: no-repeat;
  background-size: 14.5vw;
  background-position: bottom 4.2vw right 12.1vw;
}

.circle-six-en {
  background-image: url("~@/img/main-en.svg");
  background-repeat: no-repeat;
  background-size: 5vw;
  background-position: bottom 3vw right 5.2vw;
}

.circle-five-en {
  background-image: url("~@/img/school-en.svg");
  background-repeat: no-repeat;
  background-size: 14vw;
  background-position: bottom 1.9vw right 2vw;
}

.circle-four-en {
  background-image: url("~@/img/camp-en.svg");
  background-repeat: no-repeat;
  background-size: 17vw;
  background-position: bottom .6vw right 7.4vw;
}

.circle-three-en {
  background-image: url("~@/img/university-en.svg");
  background-repeat: no-repeat;
  background-size: 12vw;
  background-position: bottom 6.5vw right 3.3vw;
}

.circle-two-en {
  background-image: url("~@/img/dop-en.svg");
  background-repeat: no-repeat;
  background-size: 24vw;
  background-position: bottom 2.2vw right 2.9vw;
}

.circle-one-en {
  background-image: url("~@/img/business-en.svg");
  background-repeat: no-repeat;
  background-size: 14.5vw;
  background-position: bottom 4.1vw right 12.1vw;
}

@keyframes top-bar {
  50% {
    transform: translateY(0);
  }
  100% {
    transform: rotate(45deg) translateY(0);
  }
}

@keyframes bottom-bar {
  50% {
    transform: translateY(0);
  }
  100% {
    transform: rotate(-45deg) translateY(0);
  }
}

</style>