<template>
  <div class="info-block">
    <div class="university-block content-block">
      <h2 class="red">
        {{ this.$langStore.lang === "ru" ? "Высшее образование в Малайзии — это:" : "HIGHER EDUCATION IN MALAYSIA IS:" }}
      </h2>
      <ol v-html="list" ref="list">
      </ol>
    </div>
    <div class="text-block content-block" v-html="text" ref="textBlock">
    </div>
    <div class="program-block content-block">
      <h2 class="red">
        {{ this.$langStore.lang === "ru" ? "Система высшего образования Малайзии построена по британскому принципу и является многоступенчатой." : "MALAYSIA'S HIGHER EDUCATION SYSTEM IS BASED ON THE BRITISH EDUCATION TEMPLATE AND IS MULTI-TIERED." }}
      </h2>
      <SlideTextComponent :tabs="slider" />
    </div>
    <div class="links-block content-block">
      <h2 class="red">
        {{ this.$langStore.lang === "ru" ? "Популярные учебные заведения Малайзии" : "POPULAR EDUCATIONAL INSTITUTIONS IN MALAYSIA" }}
      </h2>
      <ul>
        <li><a href="https://academlux.com/countries/malajziya/vyisshee-obrazovanie-i-magistratura/apu-malaysia">Asia Pacific University of Technology and Innovation (Куала-Лумпур)</a></li>
        <li><a href="https://academlux.com/countries/malajziya/vyisshee-obrazovanie-i-magistratura/limkokwing-university">Limkokwing University (Сайберджая, Куала-Лумпур, Кучинг)</a></li>
        <li><a href="https://academlux.com/countries/malajziya/vyisshee-obrazovanie-i-magistratura/sunway-university">Sunway University (Куала-Лумпур)</a></li>
        <li><a href="https://academlux.com/countries/malajziya/vyisshee-obrazovanie-i-magistratura/university-of-cyberjaya">University of Cyberjaya (Сайберджая)</a></li>
        <li><a href="">UCTI & APIIT</a></li>
        <li><a href="https://www.globaldialog.ru/countries/malaysia/higher_education/university-of-nottingham-malaysia-campus/">The University of Nottingham</a></li>
        <li><a href="">HELP University College</a></li>
        <li><a href="https://www.globaldialog.ru/countries/malaysia/higher_education/monash-university-malaysia-campus/">Monash University</a></li>
        <li><a href="">Taylor's University</a></li>
      </ul>
    </div>
    <div class="content-block" style="margin-top: 0">
        <div class="button-block">
          <OrderInterviewButton />
          <OrderCallbackButton />
          <OnlineTestButton />
        </div>
      </div>
    <div class="content-block">
        <CarouselComponent :items="feedbackArray"/>

        <FeedbackForm page="university" />
      </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import SlideTextComponent from '../SlideTextComponent.vue';
import CarouselComponent from '../CarouselComponent.vue';
import FeedbackForm from '../Forms/FeedbackForm.vue';
import OrderCallbackButton from '../Buttons/OrderCallbackButton.vue';
import OrderInterviewButton from '../Buttons/OrderInterviewButton.vue';
import OnlineTestButton from '../Buttons/OnlineTestButton.vue';
export default {
  name: 'UniversityPageSecond',
  components: {
    SlideTextComponent,
    CarouselComponent,
    FeedbackForm,
    OrderCallbackButton,
    OnlineTestButton,
    OrderInterviewButton,
  },
  data() {
    return {
      listVisible: false,
      list: "",
      text: "",
      slider: [
        {
          name: "Бакалавриат",
          component: "BachelorTab"
        },
        {
          name: "Магистратура",
          component: "MagistracyTab"
        },
        {
          name: "Докторантура",
          component: "DoctoralTab"
        },
      ],
    }
  },
  setup() {
    const feedbackArray = ref([]);

    
    const loadModeratedFeedback = () => {
      fetch(process.env.VUE_APP_API_URL + `/api/feedback/moderated/university`)
          .then(response => response.json())
          .then(data => {
            if (data.detail) return;
            feedbackArray.value = data
            feedbackArray.value.forEach(element => {
                element.photo = process.env.VUE_APP_API_URL + "/api/files/feedback/" + element.photo
              });
          })
          .catch(error => console.error('Ошибка загрузки контента:', error));
    };

    loadModeratedFeedback();

    return {
      feedbackArray,
    }
  },
  mounted() {
    this.fetchContent();

    const fromLeftObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('who-visible');
            this.listVisible = true;
            this.listAnimate();
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    const fromRightObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('level-visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const listBlock = this.$refs.list;
    const textBlock = this.$refs.textBlock;

    fromLeftObserver.observe(listBlock);
    fromRightObserver.observe(textBlock);
  },
  methods: {
    listAnimate() {
      Array.from(this.$refs.list.children).forEach((item, index) => {
        item.style["animation-delay"] = (index * 0.5) + "s";
      })
    },
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/get/content/${this.$langStore.lang}/university`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
        this.list = data.list;
        this.text = data.text;
        this.slider = [
          {
            name: this.$langStore.lang === "ru" ? "Бакалавриат" : "Bachelor",
            component: "BachelorTab",
            text: data.bachelor
          },
          {
            name: this.$langStore.lang === "ru" ? "Магистратура" : "Master" ,
            component: "MagistracyTab",
            text: data.magistracy
          },
          {
            name: this.$langStore.lang === "ru" ? "Докторантура" : "Doctorate" ,
            component: "DoctoralTab",
            text: data.doctoral
          },
        ];
        this.beginner_list = data.beginner_list;
        this.beginner_text = data.beginner_text;

        this.advanced_list = data.advanced_list;
        this.advanced_text = data.advanced_text;

      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background: url('~@/img/sky-with-clouds.jpg') no-repeat;
  background-size: cover;
  padding-bottom: 4rem;
  min-height: 100dvh;
}

.info-block::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  z-index: 0;
}

:deep(.university-block) {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: black;

  ol {
      list-style: none;
      counter-reset: list-counter;
      list-style-position: inside;
    }

  ol li {
    counter-increment: list-counter;
    position: relative;
    text-align: center;
    color: black;
    visibility: hidden;
    font-size: 1.1rem;
    font-family: "HelveticaMedium";

    &:not(:last-of-type){
      margin-bottom: 1rem;
    }
  }

  ol li::before {
    content: counter(list-counter);
    position: relative;
    display: inline-block;
    left: -1rem;
    width: 30px; 
    height: 30px; 
    line-height: 30px; 
    text-align: center;
    border-radius: 50%; 
    background-color: #d91d1c;
    color: white;
    font-size: 20px;
  }
}

.text-block {
  display: flex;
  flex-direction: column;
  gap: .7rem;
  color: black;
  text-align: start;
  visibility: hidden;

  p {
    line-height: 1.1rem;
  }
}

.content-block {
  margin-top: 2rem !important;
}

.program-block {
  gap: 2rem;
}

:deep(.who-visible) {
  li {
    animation-name: slideInFromLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    visibility: visible;
  }
}

.level-visible {
  animation-name: slideInFromRight;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  visibility: visible;
}

.links-block {
  gap: 3rem;

  ul {
    list-style-position: inside;
    color: #d91d1c;
    text-align: start;

    li {
      position: relative;
      text-align: center;
      color: black;
      visibility: visible;
      // transform: translateX(-200%);
      font-size: 1rem;
      font-family: "Ermilov";

      &:not(:last-of-type){
        margin-bottom: 1rem;
      }
    }

    a {
      color: black;
    }
  }
}

.button-block {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  .button {
    width: 100%;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .button-block {
    flex-direction: column;
  }
}
</style>