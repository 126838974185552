<template>
    <div class="university-tab content-tab" v-html="text">
    </div>
</template>

<script>
export default {
    name: "BachelorTab",
    props: {
        text: String
    }
}
</script>

<style lang="scss" scoped>
    .university-tab {
        p {
            font-size: 1rem;
        }
    }
</style>