<template>
  <!--HamBurger Icon-->
  <MenuComponent/>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
  <CallbackFormComponent/>
  <OrderInterviewForm/>
  <FooterComponent/>
</template>

<script>
import MenuComponent from '@/components/Layout/MenuComponent.vue';
import FooterComponent from '@/components/Layout/FooterComponent.vue';
import CallbackFormComponent from '@/components/Modals/CallbackFormComponent.vue';
import OrderInterviewForm from '@/components/Modals/OrderInterviewForm.vue';

export default {
  name: 'App',
  components: {
    MenuComponent,
    FooterComponent,
    CallbackFormComponent,
    OrderInterviewForm
  },
}
</script>

<style lang="scss">
@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
        visibility: hidden;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
        visibility: hidden;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }
}

* {
  margin: 0;
  padding: 0;
  z-index: 1;
}

body {
  overflow-x: hidden;
}

#app {
  font-family: "HelveticaMedium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background-size: cover;
  color: #fff;
  min-height:100vmax;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.red {
  color: #d91d1c;
}

.upper {
  text-transform: uppercase;
}

.content-block {
  width: 80%;
  margin: 0 auto;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.content-tab {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: "HelveticaMedium";
  font-size: 1.1rem;
  color: black;
  margin-top: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Ag Fatum";
  text-transform: uppercase;
}

.info-block {
  min-height: 100dvh;
}

@media (max-width: 768px) {
  .desktop {
    display: none;
  }

  article.container {
    grid-column: span 2;

    h1, h2, h3, h4, h5, h6 {
      font-size: 1.8rem;
    }
  }

  .button {
    width: 75dvw !important;
    font-size: 1.3rem !important;
  }
}

@media (min-width: 769px) {
  .mobile {
    display: none;
  }
}
</style>
