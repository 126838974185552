<template>
  <div class="info-block">
    <div class="mba-block content-block">
      <h2 class="red">
        
        {{ this.$langStore.lang === "ru" ? "Магистр делового администрирования (MBA)" : "MASTER OF BUSINESS ADMINISTRATION (MBA)" }}
      </h2>
      <h3>
        
        {{ this.$langStore.lang === "ru" ? "Программы MBA, проводящиеся в Малайзии:" : "MBA PROGRAMS AVAILABLE IN MALAYSIA:" }}
      </h3>
      <ol v-html="list" ref="list">
      </ol>
    </div>
    <div class="adv-block content-block" v-html="advantages" ref="textBlock">
      
    </div>

    <div class="program-block content-block" ref="programBlock">
      <h2 class="red">
        
        {{ this.$langStore.lang === "ru" ? "Программы обучения:" : "STUDY PROGRAMS:" }}
      </h2>
      <div class="program-grid">

        <div class="program-wrapper" v-for="(item, index) in programs" :key="index">
          <div class="program">
            <div class="program-link">
              <a :href="item.link" class="red">{{ this.$langStore.lang === "ru" ? "Подробнее" : "Read More" }}</a>
            </div>
          </div>
          <div class="program-text" v-html="item.text">
          </div>
        </div>
      
      </div>
    </div>
    <div class="content-block" style="margin-top: 0">
        <div class="button-block">
          <OrderInterviewButton />
          <OrderCallbackButton />
          <OnlineTestButton />
        </div>
      </div>
    <div class="content-block">
        <CarouselComponent :items="feedbackArray"/>

        <FeedbackForm page="additional" />
      </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import CarouselComponent from '../CarouselComponent.vue';
import FeedbackForm from '../Forms/FeedbackForm.vue';
import OrderCallbackButton from '../Buttons/OrderCallbackButton.vue';
import OrderInterviewButton from '../Buttons/OrderInterviewButton.vue';
import OnlineTestButton from '../Buttons/OnlineTestButton.vue';
export default {
  name: 'AdditionalPageSecond',
  components: {
    CarouselComponent,
    FeedbackForm,
    OrderCallbackButton,
    OnlineTestButton,
    OrderInterviewButton,
  },
  data() {
    return {
      list: "",
      advantages: "",
      programs: [],
    }
  },
  setup() {
    const feedbackArray = ref([]);

    
    const loadModeratedFeedback = () => {
      fetch(process.env.VUE_APP_API_URL + `/api/feedback/moderated/additional`)
          .then(response => response.json())
          .then(data => {
            if (data.detail) return;
            feedbackArray.value = data
            feedbackArray.value.forEach(element => {
                element.photo = process.env.VUE_APP_API_URL + "/api/files/feedback/" + element.photo
              });
          })
          .catch(error => console.error('Ошибка загрузки контента:', error));
    };

    loadModeratedFeedback();

    return {
      feedbackArray,
    }
  },
  mounted() {
    this.fetchContent();

    const fromLeftObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('who-visible');
            this.listVisible = true;
            this.listAnimate();
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    const fromRightObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('level-visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 }
    );

    const programObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('program-visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 }
    );

    const listBlock = this.$refs.list;
    const textBlock = this.$refs.textBlock;
    const programBlock = this.$refs.programBlock;

    fromLeftObserver.observe(listBlock);
    fromRightObserver.observe(textBlock);
    programObserver.observe(programBlock);
  },
  methods: {
    listAnimate() {
      Array.from(this.$refs.list.children).forEach((item, index) => {
        item.style["animation-delay"] = (index * 0.5) + "s";
      })
    },
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/get/content/${this.$langStore.lang}/additional`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
        this.list = data.list;
        this.advantages = data.advantages;
        this.programs = data.programs;
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    },
    async fetchFeedback() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/feedback/moderated/all`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
        this.list = data.list;
        this.advantages = data.advantages;
        this.programs = data.programs;
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background: url('~@/img/sky-with-clouds.jpg') no-repeat;
  background-size: cover;
  padding-bottom: 4rem;
  min-height: 100dvh;
}

.info-block::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  z-index: 0;
}

:deep(.mba-block) {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  color: black;

  h2 {
    margin-bottom: 2.5rem;
  }

  ol {
    list-style: none;
    counter-reset: list-counter;
    list-style-position: inside;
  }

  ol li {
    counter-increment: list-counter;
    position: relative;
    text-align: center;
    color: black;
    visibility: hidden;
    font-size: 1.1rem;
    font-family: "HelveticaMedium";

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  ol li::before {
    content: counter(list-counter);
    position: relative;
    display: inline-block;
    left: -1rem;
    width: 30px; 
    height: 30px; 
    line-height: 30px; 
    text-align: center;
    border-radius: 50%; 
    background-color: #d91d1c;
    color: white;
    font-size: 20px;
  }
}

:deep(.adv-block) {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .advantage {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: flex-start;
    visibility: hidden;

    p {
      color: black;
      font-size: 1rem;
      text-align: start;
    }

    &:nth-of-type(2n) {
      align-items: flex-end;

      p {
        text-align: end;
      }
    }
  }
}

:deep(.program-block) {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .program-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    p {
      color: black;
    }
  }

  .program-wrapper {
    display: flex;
    flex-direction: row;
    align-self: end;
    text-align: start;
    height: 100%;
    visibility: hidden;

    .program {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #d91d1c;
      padding: 1.5rem;
      border-radius: 1rem 0 0 1rem;

      .program-link {
        display: flex;
        flex-direction: column;
        transform: rotate(270deg);

        a {
          color: white;
          text-decoration: none;
        }
      }
    }

    .program-text {
      background: white;
      color: #d91d1c;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      border-radius: 0 1rem 1rem 0;
      gap: .5rem;

      display: flex;
      flex-direction: column;

      p {
        margin-top: 0;
      }
    }

    &:nth-of-type(2n) {
      text-align: end;
    }

    &:last-of-type {
      grid-column: span 2;
      text-align: center;
    }
  }
}

:deep(.who-visible) {
  li {
    animation-name: slideInFromLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    visibility: visible;
  }
}

:deep(.level-visible) {
  .advantage {
    animation-name: slideInFromLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    visibility: visible;
  }
  .advantage:nth-child(2n) {
    animation-name: slideInFromRight;
  }
}

:deep(.program-visible) {
  .program-grid {
    .program-wrapper {
      animation-name: slideInFromLeft;
      animation-duration: 1s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
      visibility: visible;
    }
    .program-wrapper:nth-child(2n) {
      animation-name: slideInFromRight;
    }
  }
}

.button-block {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  .button {
    width: 100%;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .button-block {
    flex-direction: column;
  }

  .program-grid {
    grid-template-columns: 1fr !important;

    .program-wrapper:last-of-type {
      grid-column: 1;
    }
  }

  .program-wrapper {
    .program {
      padding: .5rem !important;
    }
    .program-text {
      padding: .5rem !important;
    }
  }
}
</style>