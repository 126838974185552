<template>
    <transition name="modal">
      <div class="modal-mask" v-if="isVisible" @click.self="closeModal">
        <div class="modal-container">
            <form ref="form" @submit.prevent="submitHandler">
                <label for="phone">
                    {{ this.$langStore.lang === "ru" ? "Введите номер телефона" : "Enter your phone" }}
                </label>
                <input name="phone" type="phone" v-model="phone">
                <label for="name">
                    {{ this.$langStore.lang === "ru" ? "Введите ваше имя" : "Enter your name" }}
                </label>
                <input name="name" type="text" v-model="name">
                <button v-if="!isSending">
                    {{ this.$langStore.lang === "ru" ? "Отправить" : "Submit" }}
                </button>
            </form>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import {EventBus} from '@/eventBus/EventBus.js';

  export default {
    name: "CallbackFormComponent",
    data() {
      return {
        isVisible: false,
        isSending: false,
        name: "",
        phone: "",
      };
    },
    created() {
        EventBus.$on('open-callback-modal', this.openModal);
    },
    beforeUnmount() {
        EventBus.$off('open-callback-modal', this.openModal);
    },
    methods: {
      openModal() {
        this.isVisible = true;
      },
      closeModal() {
        this.isVisible = false;
      },
      submitHandler() {
        this.isSending = true;
        fetch(process.env.VUE_APP_API_URL + `/api/mail`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            message: `Запрос на обратный звонок от ${this.name} по номеру ${this.phone}`,
            subject: "Запрос на обратный звонок",
            to: "manager@education-malaysia.ru, anastasia@education-malaysia.com, va13072011@gmail.com",
          }),
        })
        .then(
          () => { 
            console.log("success");
            this.isSending = false;
            this.closeModal();
          },
          (error) => {
            console.log(error);
          },
        )
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    min-width: 60vw;
    color: black;

    form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  /* Анимация */
  .modal-enter-active, .modal-leave-active {
    transition: opacity 0.5s;
  }
  .modal-enter-from, .modal-leave-to {
    opacity: 0;
  }
  </style>