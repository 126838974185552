<template>
    <div class="education-tab content-tab" v-html="text">
    </div>
</template>

<script>
export default {
    name: "PreSchoolTab",
    props: {
        text: String
    }
}
</script>

<style lang="scss" scoped>

</style>