<template>
  <div>
    <AdditionalPageFirst/>
    <AdditionalPageSecond/>
  </div>
</template>

<script>
// @ is an alias to /src
import AdditionalPageFirst from '@/components/AdditionalPage/AdditionalPageFirst';
import AdditionalPageSecond from '@/components/AdditionalPage/AdditionalPageSecond';

export default {
  name: 'MalaysiaView',
  components: {
    AdditionalPageFirst,
    AdditionalPageSecond,
  }
}
</script>

<style scoped>

</style>