<template>
    <transition name="modal">
      <div class="modal-mask" v-if="isVisible" @click.self="closeModal">
        <div class="modal-container">
          <transition name="slide-fade" mode="out-in">
            <div class="date-select" v-if="!isSubmitStage">
              <div class="tabs">
                <div v-for="(item, index) in dates" :key="index" @click="selectTab(item.weekday, item.date)" :class="{'active-button' : item.weekday === activeTab}">
                  {{ formatDate(item.date) }}
                </div>
              </div>
              <transition name="slide-fade" mode="out-in">
                <div class="dates-grid" :key="`${activeTab}_tab`">
                  <div v-for="(item, index) in hours.filter(value => !interviews[week[activeTab]].includes(value))" :key="`${index}_hour`" @click="selectTime(item)">
                    {{ item }}
                  </div>
                </div>
              </transition>
            </div>

            <div class="submit-date" v-else>
              {{ this.$langStore.lang === "ru" ? "Запись на собеседование" : "Order interview" }} {{ formatDate(getNextDays()[activeTab].date) }} {{ this.$langStore.lang === "ru" ? "в" : "at" }} {{ chosenTime }}
              <form @submit.prevent="submitHandler">
                <label for="phone">
                    {{ this.$langStore.lang === "ru" ? "Введите номер телефона" : "Enter your phone" }}
                </label>
                <input name="phone" type="phone" v-model="phone">
                <label for="name">
                    {{ this.$langStore.lang === "ru" ? "Введите ваше имя" : "Enter your name" }}
                </label>
                <input name="name" type="text" v-model="name">
                <button v-if="!isSending">
                    {{ this.$langStore.lang === "ru" ? "Отправить" : "Submit" }}
                </button>
            </form>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import { ref } from 'vue';
  import {EventBus} from '@/eventBus/EventBus.js';

  export default {
    name: "OrderInterviewForm",
    data() {
      return {
        isVisible: false,
        isSending: false,
        failedSave: false,
        isSubmitStage: false,
        dates: this.getNextDays(),
        hours: this.getHourIntervals(),
        activeTab: 0,
        activeDate: this.getNextDays()[0],
        chosenTime: "",
        phone: "",
        name: "",
        week: ["mon", "tue", "wed", "thu", "fri"]
      };
    },
    setup() {
      const interviews = ref({});

      const loadInterviews = () => {
      fetch(process.env.VUE_APP_API_URL + `/api/interview`)
          .then(response => response.json())
          .then(data => {
            if (data.detail) return;
            data.forEach(elem => {
              let curDay = elem.day;
              interviews.value[curDay] = elem.booked_time;
            });
          })
          .catch(error => console.error('Ошибка загрузки контента:', error));
      };

      loadInterviews();

      return {
        interviews,
      }
    },
    created() {
        EventBus.$on('open-interview-modal', this.openModal);
    },
    beforeUnmount() {
        EventBus.$off('open-interview-modal', this.openModal);
    },
    methods: {
      openModal() {
        this.isVisible = true;
      },
      closeModal() {
        this.isVisible = false;
        this.isSubmitStage = false;
      },
      submitHandler() {
        this.isSending = true;
        fetch(process.env.VUE_APP_API_URL + `/api/interview`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            day: this.week[this.activeTab],
            time: this.chosenTime
          }),
        }).then(
          () => {
            console.log("Booked successfully");
            this.$router.go();
          },
          (error) => {
            console.log('FAILED...', error.text);
            this.failedSave = true;
          },
        );
        if (this.failedSave) {
          this.closeModal();
          return;
        }
        fetch(process.env.VUE_APP_API_URL + `/api/mail`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            message: `Запись на собеседование ${this.activeDate} в ${this.chosenTime} от ${this.name}, номер ${this.phone}`,
            subject: "Запрос на обратный звонок",
            to: "manager@education-malaysia.ru, anastasia@education-malaysia.com, va13072011@gmail.com",
          }),
        })
        .then(
          () => { 
            console.log("success");
            this.isSending = false;
            this.closeModal();
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        )
      },
      getNextDays() {
        const dates = [];
        let i = 1;
        while (dates.length < 5) {
          const date = new Date();
          date.setDate(date.getDate() + i);
          if (date.getDay() !== 0 && date.getDay() !== 6) {
            dates.push({
              weekday: date.getDay() - 1,
              date: date
            });
          }
          i++;
        }
        return dates;
      },
      getHourIntervals() {
        const times = [];
        for (let hour = 12; hour <= 18; hour++) {
          times.push(`${hour}:00`);
        }
        return times;
      },
      updateTime() {
        this.hours = [];
        this.hours = this.getHourIntervals();
      },
      formatDate(date) {
        const options = { weekday: 'short', day: 'numeric', month: 'numeric' };
        return new Date(date).toLocaleDateString('ru-RU', options);
      },
      selectTab(index, item) {
        this.activeTab = index;
        this.activeDate = item;
        this.updateTime();
      },
      selectTime(item) {
        this.chosenTime = item;
        this.isSubmitStage = true;
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    min-width: 60vw;
    max-width: 90vw;
    color: black;

    form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  /* Анимация */
  .modal-enter-active, .modal-leave-active {
    transition: opacity 0.5s;
  }
  .modal-enter-from, .modal-leave-to {
    opacity: 0;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-family: "Ag Fatum";
    width: 100%;
    margin-bottom: 1rem;

    div {
        transition: all .5s ease;
        user-select: none;
        cursor: pointer;
        color: black;
        padding: .5rem;

        &:hover {
            border-bottom: 1px solid black;
            background: rgba(0, 0, 0, .2);
        }
    }

    .active-button {
        border-bottom: 1px solid black;
    }
  }

  .dates-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    div {
      padding: 0.5rem; 
      text-align: center;
      user-select: none;
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
          background: rgba(0, 0, 0, .2);
      }
    }
  }

  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.3s ease;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(50px);
    opacity: 0;
  }

  .slide-fade-enter-to, .slide-fade-leave-from {
    transform: translateX(0);
    opacity: 1;
  }
  </style>