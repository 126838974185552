<template>
    <div class="form">
        <h3 class="red">
            {{ this.$langStore.lang === "ru" ? "Оставьте свой отзыв!" : "Leave your feedback!" }}
        </h3>
        <form @submit.prevent="submitHandler">
            <label for="name">
                {{ this.$langStore.lang === "ru" ? "Введите ваше имя" : "Enter your name" }}
            </label>
            <input type="text" name="name" v-model="name" class="input-padding">
            <label for="feedback">
                {{ this.$langStore.lang === "ru" ? "Напишите ваш отзыв" : "Enter your feedback" }}
            </label>
            <textarea v-model="content" name="feedback" class="input-padding" id="feedback" cols="30" rows="10"></textarea>
            <label for="photo">
                {{ this.$langStore.lang === "ru" ? "Загрузите фотографию (png, jpg, jpeg), не более 10 MB." : "Upload photo (png, jpg, jpeg), not more than 10 MB." }}
            </label>
            <input ref="file" type="file" accept="image/png, image/jpeg" name="photo">
            <button type="submit">{{ this.$langStore.lang === "ru" ? "Отправить" : "Submit" }}</button>
        </form>
    </div>
</template>

<script>
export default {
    name: "FeedbackForm",
    props: {
        page: {
            type: String,
            default: "all"
        }
    },
    data() {
        return {
            content: "",
            name: "",
        }
    },
    methods: {
        async submitHandler() {
            const fileInput = this.$refs.file;
            const formData = new FormData();
            formData.append('name', this.name)
            formData.append('content', this.content)
            formData.append('page', this.page)
            if (fileInput && fileInput.files.length > 0) {
                formData.append('photo', fileInput.files[0]);
            }
        
            await fetch(process.env.VUE_APP_API_URL + '/api/feedback/save', {
                method: 'POST',
                body: formData,
            })
            .then(() => {
                this.$router.go()
            })
            .catch((e) => {
                console.log("Error sending feedback: ", e)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .form {
        margin-top: 6rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h3 {
            text-transform: uppercase;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            color: black;

            label {
                text-align: start;
            }

            textarea {
                resize: none;
            }

            .input-padding {
                padding: .5rem;
            }

            input, textarea {
                border-radius: 1rem;
                border: 1px solid #d91d1c;
            }

            input[type=file] {
                border: 0;
                border-radius: 0;
            }

            button {
                width: 50%;
                margin: 0 auto;
                font-size: 1.3rem;
                text-transform: uppercase;
                font-weight: bold;
                font-family: "Ag Fatum";
                background: #d91d1c;
                color: white;
                border: 0;
                cursor: pointer;
                border-radius: 2rem;
                box-shadow: 4px 9px 8px 0px rgba(0, 0, 0, 0.4);
                transition: all .5s ease;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
</style>