import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CampView from '../views/CampView.vue'
import SchoolView from '../views/SchoolView.vue'
import MalaysiaView from '../views/MalaysiaView.vue'
import BusinessView from '../views/BusinessView.vue'
import AdditionalView from '../views/AdditionalView.vue'
import UniversityView from '../views/UniversityView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/camp',
    name: 'Camp',
    component: CampView
  },
  {
    path: '/business',
    name: 'Business',
    component: BusinessView
  },
  {
    path: '/additional',
    name: 'Additional',
    component: AdditionalView
  },
  {
    path: '/university',
    name: 'University',
    component: UniversityView
  },
  {
    path: '/school',
    name: 'School',
    component: SchoolView
  },
  {
    path: '/malaysia',
    name: 'Malaysia',
    component: MalaysiaView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
