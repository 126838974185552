<template>
  <div>
    <UniversityPageFirst/>
    <UniversityPageSecond/>
  </div>
</template>

<script>
// @ is an alias to /src
import UniversityPageFirst from '@/components/UniversityPage/UniversityPageFirst';
import UniversityPageSecond from '@/components/UniversityPage/UniversityPageSecond';

export default {
  name: 'MalaysiaView',
  components: {
    UniversityPageFirst,
    UniversityPageSecond,
  }
}
</script>

<style scoped>

</style>