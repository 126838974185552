<template>
    <div class="info-block">
      <div class="school-education content-block">
        <div class="upper-school" v-html="upper"></div>
        <div class="age-block" ref="ageBlock">
          <div class="age-wrapper">
            <div class="age">
              <div class="age-text">
                <p>4-7</p>
                <p>{{ this.$langStore.lang === "ru" ? "лет" : "y.o" }}</p>
              </div>
            </div>
            <div class="stage">
              <p>{{ this.$langStore.lang === "ru" ? "дошкольного" : "Preschool" }}</p>
            </div>
          </div>
          <div class="age-wrapper">
            <div class="age">
              <div class="age-text">
                <p>7-13</p>
                <p>{{ this.$langStore.lang === "ru" ? "лет" : "y.o" }}</p>
              </div>
            </div>
            <div class="stage">
              <p>{{ this.$langStore.lang === "ru" ? "начального" : "Primary school" }}</p>
            </div>
          </div>
          <div class="age-wrapper">
            <div class="age">
              <div class="age-text">
                <p>13-18</p>
                <p>{{ this.$langStore.lang === "ru" ? "лет" : "y.o" }}</p>
              </div>
            </div>
            <div class="stage">
              <p>{{ this.$langStore.lang === "ru" ? "среднего обучения" : "Secondary school" }}</p>
            </div>
          </div>
        </div>
        <div class="lower-school" v-html="lower"></div>
        <div class="image-grid">
          <img src="~@/img/kids/kids_1.png" alt="">
          <img src="~@/img/kids/kids_2.jpg" alt="">
          <img src="~@/img/kids/kids_3.jpg" alt="">
          <img src="~@/img/kids/kids_4.jpg" alt="">
        </div>
      </div>
      <div class="school-block content-block">
        <SlideTextComponent :tabs="slider" />

        <div class="last-school" v-html="last"></div>
        <div class="button-block">
          <OrderInterviewButton />
          <OrderCallbackButton />
          <OnlineTestButton />
        </div>
      </div>
      <div class="content-block">
        <CarouselComponent :items="feedbackArray"/>

        <FeedbackForm page="school" />
      </div>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import SlideTextComponent from '@/components/SlideTextComponent.vue';
import OrderCallbackButton from '../Buttons/OrderCallbackButton.vue';
import OrderInterviewButton from '../Buttons/OrderInterviewButton.vue';
import OnlineTestButton from '../Buttons/OnlineTestButton.vue';
import CarouselComponent from '@/components/CarouselComponent.vue';
import FeedbackForm from '@/components/Forms/FeedbackForm.vue';

  export default {
    name: 'SchoolPageSecond',
    components: {
      SlideTextComponent,
      OrderCallbackButton,
      CarouselComponent,
      OnlineTestButton,
      OrderInterviewButton,
      FeedbackForm
    },
    data() {
      return {
        upper: "",
        lower: "",
        last: "",
        slider: [
          {
            name: this.$langStore.lang === "ru" ? "Дошкольное обучение" : "Preschool",
            component: "PreSchoolTab",
          },
          {
            name: this.$langStore.lang === "ru" ? "Начальная школа" : "Primary school",
            component: "SchoolTab",
          },
          {
            name: this.$langStore.lang === "ru" ? "Средняя школа" : "Secondary school",
            component: "MidSchoolTab",
          },
        ],
      }
    },
    setup() {
      const feedbackArray = ref([]);

      
      const loadModeratedFeedback = () => {
        fetch(process.env.VUE_APP_API_URL + `/api/feedback/moderated/school`)
            .then(response => response.json())
            .then(data => {
              if (data.detail) return;
              feedbackArray.value = data
              feedbackArray.value.forEach(element => {
                  element.photo = process.env.VUE_APP_API_URL + "/api/files/feedback/" + element.photo
                });
            })
            .catch(error => console.error('Ошибка загрузки контента:', error));
      };

      loadModeratedFeedback();

      return {
        feedbackArray,
      }
    },
    mounted() {
      this.fetchContent();
      const ageBlockObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('age-visible');
            this.listVisible = true;
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const ageBlock = this.$refs.ageBlock;

    ageBlockObserver.observe(ageBlock);
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/get/content/${this.$langStore.lang}/school`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
        this.slider = [
          {
            name: this.$langStore.lang === "ru" ? "Дошкольное обучение" : "Preschool",
            component: "PreSchoolTab",
            text: data["pre_school"],
          },
          {
            name: this.$langStore.lang === "ru" ? "Начальная школа" : "Primary school",
            component: "SchoolTab",
            text: data["school"],
          },
          {
            name: this.$langStore.lang === "ru" ? "Средняя школа" : "Secondary school",
            component: "MidSchoolTab",
            text: data["mid_school"],
          },
        ];
        
        this.upper = data.upper;
        this.lower = data.lower;
        this.last = data.last;
        

      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
  }
  </script>
  
  <style lang="scss" scoped>
    @keyframes slideInFromLeft {
        0% {
            transform: translateX(-100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slideInFromRight {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

  .info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    background: url('~@/img/sky-with-clouds.jpg') no-repeat;
    background-size: cover;
    padding-bottom: 4rem;
  }

  .info-block::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
    z-index: 0;
  }

  :deep(.school-education) {
    h2 {
      text-transform: uppercase;
      font-family: "Ag Fatum";
    }

    p {
      color: black;
      font-size: 1.2rem;
      margin-top: 2rem;
    }
  }

  :deep(.school-block) {
    p {
      font-size: 1.1rem;
      color: black;
      margin-top: 2rem;
    }

    margin-top: 1rem;
  }

  .age-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 1rem;
    visibility: hidden;

    .age-wrapper {
      display: flex;
      flex-direction: row;
      align-self: end;

      .age {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #d91d1c;
        padding: 1.5rem;
        border-radius: 1rem 0 0 1rem;

        .age-text {
          display: flex;
          flex-direction: column;
          transform: rotate(270deg);
        }

        p {
          font-size: 1rem;
          color: white;
          margin-top: 0;
        }
      }

      .stage {
        background: white;
        color: #d91d1c;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        border-radius: 0 1rem 1rem 0;
        gap: .5rem;

        display: flex;
        flex-direction: column;

        p {
          margin-top: 0;
        }
      }
    }
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    margin-top: 1rem;

    img {
      width: 100%;
      height: 100%;
      align-self: center;
      transition: all .5s ease;
      cursor: pointer;
      box-shadow: 4px 9px 8px 0px rgba(0, 0, 0, 0.4);
    }

    img:hover {
      transform: scale(1.1)
    }
  }

  .age-visible {
    animation-name: slideInFromLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    visibility: visible;
  }

  .button-block {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    .button {
      width: 100%;
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    .age-block {
      grid-template-columns: 1fr;
    }

    .button-block {
      flex-direction: column;
    }
  }
  </style>