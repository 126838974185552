<template>
    <div class="home" 
    :class="{ 'border-visible': isIntersecting }"
    @scroll.prevent="onIntersect"
    :style="{backgroundImage: `url(${bg})`}">
        <article class="container">
            <h2>{{ this.$langStore.lang === "ru" ? "Высшее образование" : "HIGHER EDUCATION" }}</h2>
        </article>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UniversityPageFirst',
    data() {
      return {
        isIntersecting: false,
        bg: `${process.env.VUE_APP_API_URL}/api/files/bg/university.jpg`
      }
    },
    methods: {
      scrollHandler() {
        this.isIntersecting = true;
        window.removeEventListener('scroll', this.scrollHandler);
      }
    },
    mounted() {
      window.addEventListener('scroll', this.scrollHandler);
    }
  }
  </script>
  
  <style scoped>
    .home {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 30px;
      min-height: 100dvh;
      background-size: cover;
      margin-bottom: 20px;
      justify-items: center;
      align-items: center;
    }

    .home::before {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 0;
      height: 20px;
      background-color: #d91d1c; /* Или любой цвет на ваш выбор */
      transition: width 1s ease-in-out;
    }

    .border-visible::before {
      width: 100%; /* Полная ширина элемента */
    }

    .home::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      pointer-events: none;
      z-index: 0;
    }
  
      .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    grid-column: 2;
    z-index: 100;
    margin-bottom: 11vh;
  }
  
  h2 {
    text-shadow: 0 10px 20px rgba(0, 0, 0, 0.6), 6px 6px 6px rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
    font-size: 3.5rem;
    font-family: "Ag Fatum";
  }
  
  h3 {
    text-shadow: 0 10px 20px rgba(0, 0, 0, 0.6), 6px 6px 6px rgba(0, 0, 0, 0.6);
    font-size: 3.5rem;
    font-family: "Ag Fatum";
    color: #d91d1c;
    margin-bottom: 1.9rem;
  }
  
  article p {
    padding-bottom: 15px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  
  article a {
    color: #fff;
    text-decoration: none;
    opacity: .2;
    font-size: 12px;
  }
  
  article a:hover {
    opacity: .8;
  }
  
  .button {
    width: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 1rem 1rem;
    border-radius: 1.5rem;
    background: white;
    color: #d91d1c;
    font-weight: bold;
    font-family: "Ag Fatum";
    text-transform: uppercase;
    font-size: 1.5rem;
    height: 2rem;
    grid-column: 2;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6), 6px 6px 6px rgba(0, 0, 0, 0.6);
  }

.overlay{
  position:relative;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.4);
}
  
  
  @media screen and (max-width:800px) {
  
    h1 {
      padding-top:80px;
      font-size: 60px;
    }
  }
      .mainmenu-enter-active, .mainmenu-leave-active {
        transition: all 1s ease-in-out;
      }
      .mainmenu-enter, .mainmenu-leave-to /* .fade-leave-active in <2.1.8 */ {
        transform: translate(-300px, -300px);
      }
  </style>