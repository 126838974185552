<template>
    <div class="tabs">
      <div v-for="(item, index) in tabs" :key="index" @click="selectTab(index)" :class="{'active-button' : index === activeTabIndex}">
        {{ item.name }}
      </div>
    </div>
    <transition name="slide-fade" mode="out-in">
      <component :is="tabs[activeTabIndex].component" :key="activeTabIndex" :text="tabs[activeTabIndex]?.text"></component>
    </transition>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeTabIndex: 0,
      };
    },
    props: {
        tabs: Array,
    },
    methods: {
      selectTab(index) {
        this.activeTabIndex = index;
      }
    }
  }
  </script>

<style lans="scss" scoped>
.tabs {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    font-family: "Ag Fatum";
    width: 100%;

    div {
        transition: all .5s ease;
        user-select: none;
        cursor: pointer;
        color: #d91d1c;
        padding: 1rem;
        text-transform: uppercase;

        &:hover {
            border-bottom: 1px solid #d91d1c;
            background: rgba(0, 0, 0, .2);
        }
    }

    .active-button {
        border-bottom: 1px solid #d91d1c;
    }
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateX(50px);
  opacity: 0;
}

.slide-fade-enter-to, .slide-fade-leave-from {
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 768px) {
  .tabs {
    flex-direction: column;
  }
}
</style>