<template>
    <div class="info-block">
      <div class="main-block" ref="animatedElement">
         <div class="main-block-headers">
          <div class="first">
            <img src="@/img/island-first.svg" alt="">
            <h2>{{ this.$langStore.lang === "ru" ? "Почему Малайзия?" : "Why Malaysia?" }}</h2>
          </div>
          <div class="second">
            <img src="@/img/island-second.svg" alt="">
            <h3 class="red">{{ this.$langStore.lang === "ru" ? "Английский — второй главный язык" : "ENGLISH IS THE SECOND MAJOR LANGUAGE" }}</h3>
          </div>
         </div>
         <div class="malaysia-advantages" v-html="content">
         </div>
    </div> 
    <div class="why-malaysia" ref="animatedElement2">
      <InlineSvg :src="require('@/img/why-malaysia-ru.svg')" v-if="this.$langStore.lang === 'ru'" />
      <InlineSvg :src="require('@/img/why-malaysia-en.svg')" v-else />
    </div>
    </div>
    


</template>
  
<script>
import InlineSvg from 'vue-inline-svg';
  export default {
    name: 'MainPageSecond',
    components: {
      InlineSvg
    },

    data() {
      return {
        content: "",
      }
    },

    mounted() {
      this.fetchContent();

      const parentObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.visibility = 'visible';
            entry.target.classList.add('animated-element');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const whyParentObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.visibility = 'visible';
            entry.target.classList.add('why-animated-element');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const parent = this.$refs.animatedElement;
    const whyParent = this.$refs.animatedElement2;

    parentObserver.observe(parent);

    whyParentObserver.observe(whyParent);
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/get/content/${this.$langStore.lang}/home/why_malaysia`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
        this.content = data[0].content;
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
  }
</script>
  
  <style lang="scss" scoped>
    @keyframes slideInFromLeft {
        0% {
            transform: translateX(-100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slideInFromRight {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes appearFromRight {
        0% {
            transform: translateX(4000%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes appearFromLeft {
        0% {
            transform: translateX(-2000%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

  .info-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    background: url('~@/img/sky-with-clouds.jpg') no-repeat;
    background-size: cover;
    min-height: 100dvh;
  }

  .info-block::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
    z-index: 0;
  }

  .main-first-block {
    img {
      height: 5vw;
      display: inline;
    }
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-weight: bold;
      font-size: 3rem;
    }
  }

  .main-block-headers {
    width: 100%;
    margin-bottom: 1rem;

    .first {
      display: flex;
      align-items: center;
      gap: 2rem;
      margin-bottom: .7rem;

      h2 {
        font-size: 2.7rem;
      }

      img {
        height: 10vh;
      }
    }

    .second {
      display: flex;
      align-items: center;
      margin-left: 6vw;
      gap: 2rem;

      img {
        width: 7vw;
        height: 7vh;
      }

      h3 {
        font-size: 1rem;
      }
    }
  }

  .main-second-block {
    img {
      width: 6vw;
      display: inline;
    }
    width: calc(100% - 5vw);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 5vw;
    margin-top: 1rem;
    margin-bottom: 1rem;
    h2 {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

.animated-element {
  animation-name: slideInFromLeft;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  visibility: hidden; /* Изначально элемент скрыт */
}

.child-element {
  animation-name: appearFromRight;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  position: absolute;
    right: 0; /* Или любое другое значение для позиционирования в углу */
    bottom: 20px; /* Или любое другое значение для позиционирования в углу */
  visibility: hidden; /* Изначально элемент скрыт */
  img {
            width: 3vw;
        }
}

.why-animated-element {
    animation-name: slideInFromRight;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    visibility: hidden; /* Изначально элемент скрыт */
    }

    .main-block {
      position: relative;
        display: flex;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        color: black;
        margin-top: 3rem;
        font-family: "HelveticaMedium";
        visibility: hidden;

        p {
          font-size: 1.2rem;
        }
    }

    .why-malaysia {
        display: flex;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        color: white;
        margin-top: 3rem;
        margin-bottom: 3rem;
        font-family: "HelveticaMedium";
        visibility: hidden;
    }

    .why-malaysia-blocks {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;
        padding: 2rem;

        .why-block {
            background: #d91d1c;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 1.5rem;
            border-radius: 1.5rem;
            padding: 1rem;
        }
    }

    :deep(.malaysia-advantages) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 2rem;

      h4 {
        font-size: 1.5rem;
      }

      p {
        font-size: 1rem;
      }
    }

    @media (max-width: 768px) {
      .info-block {
        flex-direction: column;
      }

      .main-block {
        width: 80%;
        padding: 0;
        margin: 0 auto;
        margin-top: 3rem;
      }

      .main-block-headers {
        .first h2 {
          font-size: 2rem;
        }
      }

      .why-malaysia {
        padding: 0;
        width: 80%;
      }
    }
  </style>