<template>
    <div class="info-block">
      <div class="who-block" ref="whoBlock">
        <div class="who-goal">
          <h1>
            {{ this.$langStore.lang === "ru" ? "Наш лагерь" : "Our camp" }}
          </h1>
          <h2 class="red">
            {{ this.$langStore.lang === "ru" ? "Подходит для детей от 8 до 18 лет, у которых:" : "IS SUITABLE FOR CHILDREN FROM 8 TO 18 YEARS OLD WHO:" }}
          </h2>
          <ol v-html="whoItems" ref="list">

          </ol>
        </div>
      </div>
      <div class="level-block" ref="level">
        <div class="level-block__beginner">
          <div class="level-block_text">
            <h1>
              {{ this.$langStore.lang === "ru" ? "Для начинающих" : "For beginners" }}
            </h1>
            <h2 class="red">
              {{ this.$langStore.lang === "ru" ? "Для тех, кто не знает язык, лагерь, это возможность:" : "FOR THOSE WHO DO NOT KNOW THE LANGUAGE, THE CAMP IS AN OPPORTUNITY TO:" }}
            </h2>
            <ul v-html="beginner_list">
            </ul>
          </div>
          <div class="level-block_skill">
            <div class="level-block_skill__symbol">
              A1
            </div>
            <div class="level-block_skill__text" v-html="beginner_text">
            </div>
          </div>
        </div>
        <div class="level-block__advanced">
          <div class="level-block_text">
            <h1>
              {{ this.$langStore.lang === "ru" ? "Для продвинутых" : "Advanced level" }}
            </h1>
            <h2 class="red">
              {{ this.$langStore.lang === "ru" ? "Для тех, кто знает язык, лагерь, это возможность:" : "FOR THOSE WHO KNOW THE LANGUAGE, THE CAMP IS AN OPPORTUNITY TO:" }}
            </h2>
            <ul v-html="advanced_list">
            </ul>
          </div>
          <div class="level-block_skill">
            <div class="level-block_skill__symbol">
              C1
            </div>
            <div class="level-block_skill__text" v-html="advanced_text">
            </div>
          </div>
        </div>
      </div>

      <div class="image-grid">
          <img src="~@/img/camp/2.png" alt="">
          <img src="~@/img/camp/4.png" alt="">
          <img src="~@/img/camp/5.png" alt="">
          <img src="~@/img/camp/6.png" alt="">
        </div>

      <div class="faq-block">
        <h2 class="red">
          {{ this.$langStore.lang === "ru" ? "Ответы на часто задаваемые вопросы" : "Frequently asked questions" }}
        </h2>
        <DropdownTextComponent v-for="(item, index) in faqItems" 
        :key="index" 
        :name="item.question" 
        :text="item.answer" 
      />
      </div>

      <div class="program-block">
        <h3>
          {{ this.$langStore.lang === "ru" ? "Программа:" : "Program:" }}
        </h3>
        <SlideTextComponent :tabs="slider" />
      </div>
      <div class="content-block" style="margin-top: 0">
        <div class="button-block">
          <OrderInterviewButton />
          <OrderCallbackButton />
          <OnlineTestButton />
        </div>
      </div>
      <div class="content-block">
        <CarouselComponent :items="feedbackArray"/>

        <FeedbackForm page="camp" />
      </div>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import DropdownTextComponent from '@/components/DropdownTextComponent.vue';
import SlideTextComponent from '@/components/SlideTextComponent.vue';
import CarouselComponent from '@/components/CarouselComponent.vue';
import FeedbackForm from '@/components/Forms/FeedbackForm.vue';
import OrderCallbackButton from '../Buttons/OrderCallbackButton.vue';
import OrderInterviewButton from '../Buttons/OrderInterviewButton.vue';
import OnlineTestButton from '../Buttons/OnlineTestButton.vue';
  export default {
    name: 'CampPageSecond',
    components: {
      DropdownTextComponent,
      SlideTextComponent,
      CarouselComponent,
      FeedbackForm,
      OrderCallbackButton,
      OnlineTestButton,
      OrderInterviewButton,
    },
    data() {
      return {
        listVisible: false,
        whoItems: [],

        faqItems: [],

        slider: [
          {
            name: "2 недели",
            component: "TwoWeeksTab",
          },
          {
            name: "3 недели",
            component: "ThreeWeeksTab",
          },
        ],
        
        beginner_list: "",
        advanced_list: "",
        beginner_text: "",
        advanced_text: "",
      }
    },
    setup() {
      const feedbackArray = ref([]);

      
      const loadModeratedFeedback = () => {
        fetch(process.env.VUE_APP_API_URL + `/api/feedback/moderated/camp`)
            .then(response => response.json())
            .then(data => {
              if (data.detail) return;
              feedbackArray.value = data
              feedbackArray.value.forEach(element => {
                  element.photo = process.env.VUE_APP_API_URL + "/api/files/feedback/" + element.photo
                });
            })
            .catch(error => console.error('Ошибка загрузки контента:', error));
      };

      loadModeratedFeedback();

      return {
        feedbackArray,
      }
    },
    mounted() {
      this.fetchContent();

      const whoBlockObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('who-visible');
            this.listAnimate();
            this.listVisible = true;
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    const levelBlockObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('level-visible');
            this.listVisible = true;
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    const whoBlock = this.$refs.whoBlock;
    const levelBlock = this.$refs.level;

    whoBlockObserver.observe(whoBlock);
    levelBlockObserver.observe(levelBlock);
  },

  methods: {
    listAnimate() {
      Array.from(this.$refs.list.children).forEach((item, index) => {
        item.style["animation-delay"] = (index * 0.5) + "s";
      })
    },
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/get/content/${this.$langStore.lang}/camp`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
        this.whoItems = data["list_test"];
        this.faqItems = data.faq;
        this.slider = [
          {
            name: this.$langStore.lang === "ru" ? "2 недели" : "2 weeks",
            component: "TwoWeeksTab",
            text: data.program_two,
          },
          {
            name: this.$langStore.lang === "ru" ? "3 недели" : "3 weeks",
            component: "ThreeWeeksTab",
            text: data.program_three,
          },
        ];
        this.beginner_list = data.beginner_list;
        this.beginner_text = data.beginner_text;

        this.advanced_list = data.advanced_list;
        this.advanced_text = data.advanced_text;

      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
  }
  </script>
  
  <style lang="scss" scoped>
  .info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    background: url('~@/img/sky-with-clouds.jpg') no-repeat;
    background-size: cover;
    padding-bottom: 4rem;
  }

  .info-block::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
    z-index: 0;
  }

  :deep(.who-block) {

    width: 80%;
    margin: 0 auto;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    text-align: center;

    .who-goal {
      font-family: "Ag Fatum";
      margin-bottom: 2rem;

      h1 {
        color: black;
        font-size: 3rem;
        text-transform: uppercase;
      }

      h2 {
        font-size: 2rem;
        margin-bottom: 3rem;
      }

      ol {
        list-style: none;
        counter-reset: list-counter;
        list-style-position: inside;
      }

      ol li {
        counter-increment: list-counter;
        position: relative;
        text-align: center;
        color: black;
        visibility: visible;
        transform: translateX(-200%);
        font-size: 1.5rem;
        font-family: "HelveticaMedium";

        span {
          font-family: "Ermilov";
        }

        &:not(:last-of-type), p:not(:last-of-type) {
          margin-bottom: 1rem;
        }

        p:nth-of-type(1) {
          margin-top: 1rem;
        }

        p {
          font-weight: 400;
          font-size: 1rem;
        }
      }

      ol li::before {
        content: counter(list-counter);
        position: relative;
        display: inline-block;
        left: -1rem;
        width: 30px; 
        height: 30px; 
        line-height: 30px; 
        text-align: center;
        border-radius: 50%; 
        background-color: #d91d1c;
        color: white;
        font-size: 20px;
      }
    }
  }

  .level-block {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 80%;
    margin: 0 auto;
    color: black;
    margin-bottom: 2rem;
    font-family: "Ermilov";

    h1 {
      color: black;
      font-size: 2rem;
      font-family: "Ag Fatum";
      text-transform: uppercase;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      font-family: "Ag Fatum";
    }

    ul {
      list-style-position: inside;
    }

    .level-block__beginner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      visibility: hidden;

      .level-block_skill {
        align-self: start;
      }
    }

    .level-block__advanced {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      visibility: hidden;

      .level-block_text {
        grid-column: 2;
        justify-self: start;
      }

      .level-block_skill {
        grid-column: 1;
        grid-row: 1;
      }
    }

    :deep(.level-block_skill) {
      display: flex;
      flex-direction: row;
      align-self: end;

      .level-block_skill__symbol {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        background: #d91d1c;
        color: white;
        padding: 1.5rem;
        border-radius: 1rem 0 0 1rem;
      }

      .level-block_skill__text {
        background: white;
        color: #d91d1c;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        border-radius: 0 1rem 1rem 0;
        gap: .5rem;

        display: flex;
        flex-direction: column;

        h3 {
          font-size: 1.8rem;
          font-family: "Ag Fatum";
        }

        p {
          color: black;
          font-size: 1rem;
        }
      }
    }
  }

  .faq-block, .program-block {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 80%;
    margin: 0 auto;
    color: black;
    margin-bottom: 2rem;
    font-family: "Ermilov";

    h3, h2 {
      text-transform: uppercase;
      font-family: "Ag Fatum";
    }
  }

  :deep(.who-visible) {
    div ol li {
      animation-name: slideInFromLeft;
      animation-duration: 1s;
      animation-timing-function: ease-out;
      animation-delay: 0s;
      animation-fill-mode: forwards;
      visibility: visible;
    }
  }

  .level-visible {
    .level-block__beginner {
      animation-name: slideInFromLeft;
      animation-duration: 1s;
      animation-timing-function: ease-out;
      animation-delay: 0s;
      animation-fill-mode: forwards;
      visibility: visible;
    }

    .level-block__advanced {
      animation-name: slideInFromRight;
      animation-duration: 1s;
      animation-timing-function: ease-out;
      animation-delay: 0s;
      animation-fill-mode: forwards;
      visibility: visible;
    }
  }

    .red {
      color: #d91d1c;
    }

    .list-enter-active, .list-leave-active {
      transition: transform 1s;
    }
    .list-enter-from, .list-leave-to {
      transform: translateX(-100%);
      opacity: 0;
    }

    @media (max-width: 768px) {
      .level-block {
        .level-block__beginner {
          grid-template-columns: 1fr;
        }
        .level-block__advanced {
          grid-template-columns: 1fr;

          .level-block_text {
            grid-column: 1;
          }
        }
      }
    }

    .image-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1.5rem;
      margin-top: 1rem;
      width: 80%;

      img {
        width: 100%;
        height: 100%;
        align-self: center;
        transition: all .5s ease;
        cursor: pointer;
        box-shadow: 4px 9px 8px 0px rgba(0, 0, 0, 0.4);
      }

      img:hover {
        transform: scale(1.1)
      }
    }

    .button-block {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .button {
        width: 100%;
        font-size: 1rem;
      }
    }

    @media (max-width: 768px) {
      .button-block {
        flex-direction: column;
      }
    }
  </style>