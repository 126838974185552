<template>
  <div>
    <SchoolPageFirst/>
    <SchoolPageSecond/>
  </div>
</template>

<script>
// @ is an alias to /src
import SchoolPageFirst from '@/components/SchoolPage/SchoolPageFirst';
import SchoolPageSecond from '@/components/SchoolPage/SchoolPageSecond';

export default {
  name: 'SchoolView',
  components: {
    SchoolPageFirst,
    SchoolPageSecond,
  }
}
</script>

<style scoped>

</style>