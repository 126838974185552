import { reactive } from 'vue';

export const EventBus = reactive({
  events: {},
  
  $on(event, handler) {
    if (!this.events[event]) {
      this.events[event] = new Set();
    }
    this.events[event].add(handler);
  },

  $off(event, handler) {
    if (this.events[event]) {
      this.events[event].delete(handler);
    }
  },

  $emit(event, ...args) {
    if (this.events[event]) {
      this.events[event].forEach(handler => handler(...args));
    }
  }
});