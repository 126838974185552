<template>
  <div>
    <MalaysiaPageFirst/>
    <MalaysiaPageSecond/>
  </div>
</template>

<script>
// @ is an alias to /src
import MalaysiaPageFirst from '@/components/MalaysiaPage/MalaysiaPageFirst';
import MalaysiaPageSecond from '@/components/MalaysiaPage/MalaysiaPageSecond';

export default {
  name: 'MalaysiaView',
  components: {
    MalaysiaPageFirst,
    MalaysiaPageSecond,
  }
}
</script>

<style scoped>

</style>